import React from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import Ad from 'components/Ad';
import Wrapper from 'components/Wrapper';

const Main = styled.div`
	width: 100%;
	min-height: 600px;
`;

export default props => {
	return (
		<Layout>
			<Wrapper>
				<Main>
					<Ad type="insticator" />
				</Main>
			</Wrapper>
		</Layout>
	);
};
